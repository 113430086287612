export const PREF_LOCALE = 'locale'
export const PREF_THEME = 'theme'
export const PREF_ENABLE_EMAIL_NOTIFICATION = 'enableEmailNotification'
export const PREF_FLOW_FAILED_EMAIL_NOTIFICATION = 'flowFailedEmailNotification'
export const PREF_MESSAGE_FAILED_EMAIL_NOTIFICATION = 'messageFailedEmailNotification'
export const PREF_DISK_FULL_EMAIL_NOTIFICATION = 'diskFullEmailNotification'
export const PREF_HEARTBEAT_FAILED_EMAIL_NOTIFICATION = 'heartbeatFailedEmailNotification'
export const PREF_HEARTBEAT_MAIL_INTERVAL = 'heartbeatMailInterval'

export const LOCALES = Object.freeze([
  {
    label: 'preferences.locale.en-US',
    value: 'en-US'
  },
  {
    label: 'preferences.locale.de-DE',
    value: 'de-DE'
  }
])

export const THEMES = Object.freeze([
  {
    label: 'preferences.theme.auto',
    value: 'auto'
  },
  {
    label: 'preferences.theme.light',
    value: 'light'
  },
  {
    label: 'preferences.theme.dark',
    value: 'dark'
  }
])
